<template>
  <div>
    <el-card>
      <div class="account_top">
        <img :src="dataInfo.operatorUrl" alt="" />
        <div class="title">{{ dataInfo.operatorName }}</div>
      </div>

      <div class="switch_tab">
        <div style="padding-left: 20px; margin-bottom: 20px">
          <el-button type="primary" @click="addaccount()">新增</el-button>
        </div>
        <div class="label_management">
          <el-table
            :data="dataList"
            stripe
            @sort-change="changeSort"
            style="width: 100%"
            class="in_table"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column label="编号" align="center" prop="id"></el-table-column>

            <el-table-column label="姓名" prop="userName" align="center">
              <template v-slot="{ row }">
                <span>{{ row.userName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="角色" prop="" align="center">
              <template v-slot="{ row }">
                <span>{{ row.roleName }}</span>
              </template>
            </el-table-column>

            <el-table-column label="手机号" prop="mobile" align="center"></el-table-column>
            <el-table-column label="创建人" prop="userName" align="center"></el-table-column>
            <el-table-column label="是否可登录H5" prop="time" align="center">
              <template v-slot="{ row }">
                <el-switch
                  @change="notState(row)"
                  v-model="row.isHFive"
                  :active-text="row.isHFive ? '是' : '否'"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="time" align="center" sortable="">
              <template v-slot="{ row }">
                <span>{{ row.createTime }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="new_page" v-if="total">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </el-card>
    <el-dialog title="新增" :visible.sync="accountDialogVisible" width="50%" class="account_dialog">
      <el-form label-width="100px" :model="adminUser" :rules="adminrules" ref="accountTypeDialog">
        <el-form-item label="手机号:" prop="mobile">
          <el-input v-model="adminUser.mobile" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="真实姓名:" prop="userName">
          <el-input v-model="adminUser.userName" placeholder="请输入真实姓名" />
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitAccountTypeForm">确 定</el-button>
        <el-button @click="accountDialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { operatorUserPage, selectById, isHFive, operatorUserSave } from '@/api/operatorCenter'

const defaultQueryInfo = Object.freeze({
  operatorId: 0,
  orderByParam: '',
  pageNum: 1,
  pageSize: 10
})
export default {
  name: 'Account',
  computed: {
    accountId() {
      return this.$route.query.id
    }
  },
  data() {
    return {
      accountDialogVisible: false,
      dataInfo: {},
      dataList: [],
      total: 0,
      adminUser: {
        mobile: '', //	手机号
        operatorId: '',
        userName: '' //用户名
      },
      adminrules: {
        userName: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          // 这个只能验证手机号
          // { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur" }
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入合法手机号',
            trigger: 'blur'
          }
        ]
      },
      queryInfo: { ...defaultQueryInfo }
    }
  },
  created() {
    this.getAccountById()
    this.getAccount()
  },
  methods: {
    //点击新增
    addaccount() {
      this.accountDialogVisible = true
    },
    // 新增点击确定
    submitAccountTypeForm(formName) {
      this.adminUser.operatorId = this.accountId
      this.$refs[formName].validate((valid) => {
        if (valid) {
          operatorUserSave(this.adminUser).then(({ data: res }) => {
            if (res.resultCode === 200) {
              this.getAccount()
              this.accountDialogVisible = false
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.getAccount()
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.getAccount()
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'time') {
        if (val.order == 'ascending') {
          //updateAt列升序时
          this.queryInfo.orderByParam = 'r.create_time ASC'
          this.getAccount()
        } else if (val.order == 'descending') {
          //updateAt列降序时
          this.queryInfo.orderByParam = 'r.create_time DESC'
          this.getAccount()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = ''
          this.getAccount()
        }
      }
    },
    //账号启用禁用
    notState(row) {
      const query = {
        id: row.id
      }
      this.$alert('您是否确认操作？')
        .then(function () {
          return isHFive(query)
        })
        .then(() => {
          this.getAccountById()
          this.getAccount()
          this.$message.success('操作成功')
        })
        .catch(() => {})
    },
    // 获取详情
    async getAccountById() {
      const { data: res } = await selectById({ id: this.accountId })
      if (res.resultCode === 200) {
        this.dataInfo = res.data
      }
    },
    // 获取详情表格
    async getAccount() {
      this.queryInfo.operatorId = this.accountId
      const { data: res } = await operatorUserPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.dataList = res.data.list
        this.total = res.data.total
      }
    }
  }
}
</script>

<style lang="less" scoped>
.account_top {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  box-sizing: border-box;

  color: #151515;
  img {
    width: 67px;
    height: 54px;
    border-radius: 5px;
    margin-right: 20px;
  }
}

.switch_tab {
  background: #ffffff;
  margin-top: 20px;

  .essential_information {
    padding: 30px 40px;
    .general_input {
      width: 845px;
      border: 1px solid #d4d4d4;
      padding: 14px 16px;

      .input_flex {
        display: flex;
        justify-content: space-between;
        padding: 7px 0px;
        span {
          padding-left: 15px;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
    .img_tips {
      position: relative;
      top: -10px;
      color: #6ba1ff;
    }

    h4 {
      color: #146aff;
    }
    .dialog-footer {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
